<template>
  <!--  外卖商品详情页 -->
  <div class="productDetails">
    <div class="head">
      <img
        class="back"
        src="../../../assets/img/commoditySpike/back.png"
        alt="返回"
        @click="$router.back()"
      />

      <div class="title">商品详情</div>
      <div class="left">
        <img
          class="chat"
          src="../../../assets/img/takeOutFood/Chat.png"
          alt=""
        />
        <img
          @click="isOpenShare"
          class="share"
          src="../../../assets/img/takeOutFood/share.png"
        />
      </div>

      <van-share-sheet v-model="showShare" :options="options" />
    </div>
    <div class="content">
      <div class="box">
        <img
          class="display-img"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb2ffe39ee98f0d1144fc56dea45a6e1cbb807bccb3a3ddcf35b3efef2538d952"
        />
      
      <div class="wrap">
        <div class="commodity">
          <div class="commodity-name">
            【人气】无骨烤鱼饭+娃娃菜+金针菇+豆皮+豆芽
          </div>
          <div class="collection">
            <img
              class="collection-icon"
              src="../../../assets/img/takeOutFood/collection.png"
              alt=""
            />
            <div>收藏</div>
          </div>
        </div>

        <div class="lable">
          <div class="lable-item">双人份</div>
          <div class="lable-item">香辣</div>
        </div>

        <div class="sell-num">月售234份</div>

        <div class="prices">
          <div class="price"><span>￥</span>37.45</div>
          <div class="buycart">+ 加入购物车</div>
        </div>

        <table class="good-desc">
          <th class="good-detail">
              <div class="active">详情</div> </th>

          <th >
              <div>评价713</div></th>
          <tr>
            <td>套餐内容：</td>
            娃娃菜+豆皮+豆芽+金针菇+鱼
            <td></td>
          </tr>
          <tr>
            <td>主料：</td>
            鱼肉
            <td></td>
          </tr>
          <tr>
            <td>辅料：</td>
            豆皮、金针菇、娃娃菜、黄豆芽
            <td></td>
          </tr>
          <tr>
            <td>凉热：</td>
            热
            <td></td>
          </tr>
          <tr>
            <td>分量：</td>

            1人份
            <td></td>
          </tr>
          <tr>
            <td>口味：</td>
            香辣
            <td></td>
          </tr>
        </table>

        <div class="comment-box">
          <div class="comment">评价<span>(好评度100%)</span></div>
          <div class="comment-num">
           <span> 796条评论 </span>
             <img
              class="comment-icon"
              referrerpolicy="no-referrer"
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngfaf148430565f9e81574d47db3b523fa357e65e6a059a446401be9b1c7e6fab0"
            />
          </div>
        </div>
        <div class="comment-list">
          <div class="comment-item">
          <img class="comment-img" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb2ffe39ee98f0d1144fc56dea45a6e1cbb807bccb3a3ddcf35b3efef2538d952" alt="">
          <div class="comment-desc">
            <div class="comment-name">小江睡不醒  <div class="time">2012/07/26</div></div>
            <div class="comment-desc">这家的烤鱼已经点过很多次了，特别喜欢他家的烤鱼，配菜也很多。</div>
          </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      width: "", //保存屏幕宽度
      isShow: 0, //是否显示拼团规则
      showShare: false,
      options: [
        { name: "微信", icon: "wechat" },
        { name: "微博", icon: "weibo" },
        { name: "复制链接", icon: "link", description: "描述信息" },
        { name: "分享海报", icon: "poster" },
        { name: "二维码", icon: "qrcode" },
      ],
    };
  },
  methods: {
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      console.log(111);
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
    isOpenShare() {
      this.showShare = true;
    },
  },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },
  beforeDestroy(){
     document.documentElement.style.fontSize =  "16px";
  }
};
</script>

<style  scoped>
.productDetails{
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
}
.head {
  width: 100%;
  padding: 0.533333rem 0.426667rem 0.266667rem 0.266667rem;
  background-color: white;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  align-items: center;
}
.head .back {
  width: 0.666667rem;
  height: 0.64rem;
  text-align: left;
}
.head .title {
  margin-left: 1.066667rem;
  font-size: 0.453333rem;
  font-weight: 600;
  flex: 1;
  text-align: center;
}
.head .left {
  display: flex;
  align-items: center;
}
.head .chat {
  width: 0.64rem;
  height: 0.64rem;
  margin-right: 0.266667rem;
}
.head .share {
  width: 0.64rem;
  height: 0.64rem;
}
.content {
  flex: 1;
  box-sizing: border-box;
  overflow: auto;
  
}
.content::-webkit-scrollbar{
  display: none;
}

.display-img {
  width: 100%;
  height: 4.666rem;
  margin-bottom: 0.4rem;
}
.wrap {
  padding: 0 0.4324rem;
  box-sizing: border-box;
  padding-bottom: 1.333rem;
}
.commodity {
  display: flex;
  align-items: center;
  height: 1.28rem;
  margin-bottom: 0.213333rem;
}
.commodity-name {
  font-size: 0.453333rem;
  width: 7.493333rem;
  margin-right: 0.746667rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.64rem;
}
.collection {
  display: flex;
  flex-direction: column;
}
.collection-icon {
  width: 0.64rem;
  height: 0.64rem;
}
.collection div {
  text-align: center;
  font-size: 11.9991px;
  transform: scale(0.83);
}
.lable {
  display: flex;
  font-size: 0.3243rem;
  color: #999999ff;
  margin-bottom: 0.186667rem;
}
.lable-item {
  background-color: #eeeeeeff;
  margin-right: 0.32rem;
  padding: 0 0.106667rem;
  font-size: .32rem;
}
.sell-num {
  color: #999999ff;
  font-size: 0.32rem;
  margin-bottom: 0.32rem;
}
.prices {
  display: flex;
  height: 0.88rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
}
.price {
  font-size: 0.64rem;
  color: #a80000ff;
}
.price span {
  font-size: 0.373333rem;
}
.buycart {
  width: 2.693rem;
  height: 0.746rem;
  background: linear-gradient(135deg, #ff7200 0%, #ff3c00 100%);
  border-radius: 0.16rem;
  color: white;
  text-align: center;
  line-height: 0.746rem;
  font-size: 0.32rem;
}
.good-desc{
    width: 100%;
    text-align: left;
    font-size: 0.3243rem;
    color: #999999;
}
.good-desc th{
height: 1.093333rem;
font-size: 0.426rem;


color: #999999FF;
line-height: 1.093333rem;

}
.good-desc th div{
  height: 1.093333rem;
  display: inline-block;
    margin-bottom: .4rem;
}
.active{
  color: #333333;
  border-bottom: 1px solid #FF5200;
 
}
.comment-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.093333rem;
  width: 100%;
  color: #999999FF;
}
.comment{
display: flex;
align-items: center;

font-size: 0.4324rem;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #333333;
}
.comment span{
  transform:scale(0.83);
  display: block;
  color: #999999;
  font-size: .32rem;
  margin-right: .533333rem;
  
}
.comment-num {
  display: flex;
  align-items: center;
  font-size: .32rem;
}

.comment-num .comment-icon{
 
  width: .213333rem;
  height: .373333rem;
  
  margin-left: .4rem;
 
}
.comment-list{
  padding-bottom: .346667rem;
}
.comment-item{
  display: flex;
  align-items: center;
  padding-bottom: .4rem;
  border-bottom: 1px solid #ccc;
}
.comment-img{

  height: 1.466667rem;
  width: 1.466667rem;
  border-radius: 50%;
  margin-right: .266667rem;

}
.comment-name{
  color: black;
  margin-top: .08rem;
  font-size: .373333rem;
  font-weight: 600;
  font-family: PingFangSC-Regular, PingFang SC;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: .16rem;
}
.time{
  font-size: 0.3243rem;
  color: #999999FF;
  font-weight: 400;
}
.comment-desc{


font-size: 0.32rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #999999;

}
</style>